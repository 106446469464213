import { Drawer, FormControl, Paper, Stack, styled } from '@mui/material';
import React, { ReactElement, useState } from 'react';
import Grid from '@mui/material/Grid';
import AppSnackbar from 'components/snackbar/snackbar';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  MenuItem,
  Popover,
  Select,
  TextField,
  Typography,
  Divider,
  FormControlLabel,
  Switch,
  Tooltip
} from '@material-ui/core';
import { Tab, Tabs } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import EditIcon from '@mui/icons-material/Edit';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import WorkIcon from '@mui/icons-material/Work';
import ClearIcon from '@mui/icons-material/Clear';
import useStyles from '../styles/deal-edit-drawer-styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchive } from '@fortawesome/free-solid-svg-icons';
import DealEditDrawerTabs from './deal-edit-drawer-tabs';
import {
  DealAttachment,
  DealCustomerContacts,
  DealDetails,
  DealProduct,
  PipelineStageList,
  ProductCondition,
} from '../model/deal-edit-drawer';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import DealDetailsAPI from '../apis/deals-details-api';
import DealContext from '../contexts/deal-context';
import { useAppDispatch, useAppSelector } from 'app';
import { Editor } from '@tinymce/tinymce-react';
import {
  CustomerContacResponse,
  CustomerTreeOption,
  DealItem,
  PipelineListResponse,
  PipelineUsersRequest,
} from '../model/dealsInterfaces';
import { LostDealDrawer } from './lostDealDrawer';
import { WonDealDrawer } from './wonDealDrawer';
import { ArchiveDealDrawer } from './archiveDealDrawer';
import { CustomerContactDrawer } from '../../customer/components/CustomerDrawer/CustomerContactDrawer';
import CustomerContactTreeView from './customer-contact-treeview';
import { CustomerDto } from 'common/models';
import CustomerRootPageDrawer from 'components/customer/components/CustomerDrawer/CustomerRootPageDrawer';
import CustomerRootDrawer from 'components/customer/components/CustomerDrawer/CustomerRootDrawer';
import Swal from 'sweetalert2';
import FilterListIcon from '@mui/icons-material/FilterList';
import TaskboardAPI from '../../lead/components/task-board/taskboard-api';
import User from 'components/setting/models/user';
import dealsApi from '../apis/deals-api';
import { fetchTasks } from 'components/lead/components/task-board/taskboardSlice';
import { DealStatusEnum } from '../model/DealStatusEnum';
import useUsersList from 'components/setting/components/department/userUsersList';
import DescriptionIcon from '@mui/icons-material/Description';
import ImageModal from 'components/messaging/components/conversation-panel/image-modal';
import PDFIcon from 'common/components/icons/pdf';
import ImageIcon from '@mui/icons-material/Image';
import PdfPreviewModal from './PdfPreviewModal';
import { getFileExtension } from 'common/utils/shared/getFileExtension';
import DaysOpenIcon from '@mui/icons-material/AvTimer';
import DealsCalendarIcon from '@mui/icons-material/CalendarMonth';
import { format } from 'date-fns';
import GroupsIcon from '@mui/icons-material/Groups';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import config from 'config';
		
const textEditorApiKey = config.get('api.textEditor');

const StageDays = styled(Paper)(({ theme }) => ({
  color: '#fff !important',
  backgroundColor: '#28a62e !important',
  fontSize: '0.8rem',
  textAlign: 'center',
  height: '15px',
  width: '56px',
  padding: '4px',
  border: '1px solid black',
  transform: 'skewX(334deg)',
}));

function DealEditDrawer({
  open,
  dealItem,
  openedFromTasksBoard,
  renderDeals,
  onClose,
}: {
  open: boolean;
  dealItem: DealItem;
  openedFromTasksBoard: boolean;
  renderDeals: () => void;
  onClose: any;
}) {
  const [showSnackBar] = AppSnackbar.useSnackBar();
  const classes: any = useStyles();

  let initialValues: DealDetails = {
    dealId: 0,
    title: '',
    amount: 0,
    description: '',
    assignedUserId: '',
    assignedUserName: '',
    pipelineId: 0,
    pipelineName: '',
    pipelineStageId: 0,
    pipelineStageName: '',
    pipelineStagePosition: 0,
    dealStateId: 0,
    dealAttachments: [],
    dealAttachmentsToUpload: [],
    dealTimelines: [],
    dealType: '',
    createdDate: null,
    dealCloseDate: null,
    dealArchiveDate: null,
    dealStageDays: [],
    dealStageDaysObject: [],
    dealCustomerContacts: [],
    dealProducts: [],
    isTaxExempted: false,
  };
  const schema = Yup.object<any>({
    title: Yup.string().required('Deal Title Required').max(50, 'Max 50 characters allowed.'),
    amount: Yup.string().required('Amount Required'),
  });
  const dealContext = React.useContext(DealContext);
  const dealerId = useAppSelector((state) => state.user.dealerId);
  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  const dispatch = useAppDispatch();
  const dealerUsers = useUsersList(dealerId);

  const [loading, setLoading] = React.useState(false);
  const [openDrawer, SetOpenDrawer] = React.useState<any>(open);
  const [enableEditDealTitle, setEnableEditDealTitle] = React.useState<boolean>(false);
  const [enableEditDealAmount, setEnableEditDealAmount] = React.useState<boolean>(false);
  const [enableEditDealAssignment, setEnableEditDealAssignment] = React.useState<boolean>(false);
  const [enableEditDealPipeline, setEnableEditDealPipeline] = React.useState<boolean>(false);
  const [enableEditDealStage, setEnableEditDealStage] = React.useState<boolean>(false);
  const [lostDealDrawerState, setLostDealDrawerState] = React.useState(false);
  const [wonDealDrawerState, setWonDealDrawerState] = useState(false);
  const [archiveDealDrawerState, setArchiveDealDrawerState] = useState(false);
  const [hoverTitleAmount, setHoverTitleAmount] = useState(false);
  const [hoverAssignment, setHoverAssignment] = useState(false);
  const [hoverPipeline, setHoverPipeline] = useState(false);
  const [hoverStage, setHoverStage] = useState(false);
  const [dealOldValues, SetDealOldValues] = useState<DealDetails | null>(null);
  const [taskTypes, setTaskTypes] = React.useState<any[]>([]);
  const [taskOutcomes, setTaskOutcomes] = React.useState<any>([]);
  const [pipelineUsers, setPipelineUsers] = React.useState<User[] | undefined>();
  const [pipelines, setPipelines] = React.useState<PipelineListResponse[] | undefined>();
  const [pipelineStages, setPipelineStages] = React.useState<PipelineStageList[] | undefined>();
  //States for cutomer contacts
  const [customerContactDrawerState, setCustomerContactDrawerState] = useState(false);
  const [selectedContacts, setSelectedContacts] = React.useState<number[]>([]);
  const [selectedCustomers, setSelectedCustomers] = useState<CustomerDto[] | undefined>([]);
  const [customerTreeOptions, setCustomerTreeOptions] = React.useState<CustomerTreeOption[]>([]);
  const [newCustomerContact, setNewCustomerContact] = useState<CustomerContacResponse | null>(null);
  const [enableCustomerValidation, setEnableCustomerValidation] = useState<boolean>(false);
  const [filesToUpload, setFilesToUpload] = useState<DealAttachment[]>([]);
  const [removedDealAttachments, setRemovedDealAttachments] = useState<DealAttachment[]>([]);
  //States for cutomer contacts

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: (dealDetails: DealDetails) => {
      handleUpdateDeal(dealDetails);
    },
  });

  const fetchPipelineUsers = async (departmentIds: any[]) => {
    const request: PipelineUsersRequest = {
      pipelineDepartments: departmentIds,
    };
    const response = await dealsApi.PipelineDeals.getPipelineUsers(request);
    setPipelineUsers(response);
  };

  const fetchPipelinesAndUsers = async () => {
    if (dealContext.pipeline && dealContext.user) {
      setPipelines(dealContext.pipeline);
      setPipelineUsers(dealContext.user);
    } else {
      try {
        await dealsApi.PipelineDeals.getDealsPipelinesDD(dmsDealerIdCrm).then(async (data) => {
          if (data && data.length > 0) {
            setPipelines(data);
            const departmentIds = data[0].departmentIds;
            if (departmentIds && departmentIds.length > 0) {
              fetchPipelineUsers(departmentIds);
            }
          }
        });
      } catch (error: any) {
        showSnackBar({ type: 'error', message: 'Something went wrong' });
      }
    }
  };

  const getPipelinesStagesDD = React.useCallback(async (pipelineid: number): Promise<void> => {
      const data = await DealDetailsAPI.getPipelinesStagesDD(pipelineid);
      setPipelineStages(data);
  },[1]);

  const fetchDealDetails = React.useCallback(async (): Promise<void> => {
    try {
      if (dealItem.id) {
        setLoading(true);
        const response = await DealDetailsAPI.getDealDetails(dealItem.id);
        SetDealOldValues(response);
        formik.setValues(response, true);
        if(response.dealProducts.length>0 && response.amount==0)
          formik.setFieldValue('amount',Number(response.dealProducts.reduce((sum, item) => sum + item.price, 0)));
        await getPipelinesStagesDD(response.pipelineId);
        setLoading(false);
      }
    } catch (error: any) {
      formik.setValues(initialValues, true);
      setLoading(false);
      showSnackBar({ type: 'error', message: 'Something went wrong' });
    }
  }, [dealItem, formik]);

  function resetEditDealDrawer() {
    //Empty customer contacts tree view
    setSelectedCustomers([]);
    setCustomerTreeOptions([]);
    setSelectedContacts([]);
    setNewCustomerContact(null);
    setEnableCustomerValidation(false);

    //Reset values state
    setEnableEditDealTitle(false);
    setEnableEditDealAmount(false);
    setEnableEditDealAssignment(false);
    setEnableEditDealPipeline(false);
    setEnableEditDealStage(false);
    setHoverTitleAmount(false);
    setHoverAssignment(false);
    setHoverPipeline(false);
    setHoverStage(false);
    setFilesToUpload([]);
    setRemovedDealAttachments([]);
  }

  React.useEffect(() => {
    if (open === true) {
      SetOpenDrawer(open);
      fetchPipelinesAndUsers();
      fetchDealDetails();
    }
  }, [open]);

  const getTaskTypeList = React.useCallback(async (): Promise<void> => {
    const data = await TaskboardAPI.getTaskTypes();
    setTaskTypes(data);
  }, [1]);

  const getOutComeList = React.useCallback(async (): Promise<void> => {
      const data = await TaskboardAPI.getTaskOutcomes();
      setTaskOutcomes(data);  
  }, [1]);

  React.useEffect(() => {
    getTaskTypeList();
    getOutComeList();
  }, []);

  async function handleUpdateDeal(dealDetails: DealDetails): Promise<void> {
    let prevcustomerContacts = dealDetails.dealCustomerContacts;
    try {
      //Setting the customers contacts value
      let customerContacts: DealCustomerContacts[] = [];
      if (selectedContacts?.length > 0) {
        customerTreeOptions.forEach((cusOpt: CustomerTreeOption) => {
          const contacts = cusOpt.nodes?.filter((x) => selectedContacts.includes(x.value));
          const contactsReq = contacts?.map((x) => ({
            customerId: x.customerId,
            contactId: x.value,
          })) as DealCustomerContacts[];
          customerContacts = customerContacts.concat(contactsReq);
        });
      }
      if (selectedCustomers && selectedCustomers?.length > 0) {
        const customersAlreadyInTreeView = customerContacts.map((x) => x.customerId);
        selectedCustomers
          .filter((x) => !customersAlreadyInTreeView.includes(x.id))
          .forEach((cus: CustomerDto) => {
            const contactsReq = {
              customerId: cus.id,
              contactId: 0,
            } as DealCustomerContacts;
            customerContacts.push(contactsReq);
          });
      } else {
        setEnableCustomerValidation(true);
        return;
      }
      formik.setFieldValue('dealCustomerContacts', customerContacts);
      dealDetails.dealCustomerContacts = customerContacts;

      setLoading(true);
      const response = await DealDetailsAPI.updateDeal(dealerId, dealItem.id, dealDetails, removedDealAttachments);
      
      if (response == 'Success') {
        onClose(0);
        resetEditDealDrawer();
        renderDeals();
        if (openedFromTasksBoard) {
          dispatch(fetchTasks());
        }
        showSnackBar({ type: 'success', message: 'Deal updated successfully.' });
      } else {
        formik.setFieldValue('dealCustomerContacts', prevcustomerContacts);
        showSnackBar({ type: 'error', message: 'Unable to update deal.' });
        throw new Error('Unable to update deal.');
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      formik.setFieldValue('dealCustomerContacts', prevcustomerContacts);
      showSnackBar({ type: 'error', message: 'Unable to update deal.' });
      throw new Error('Unable to update deal.');
    }
  }

  async function handleReopenDeal(): Promise<void> {
    try {
      setLoading(true);
      await dealsApi.PipelineDeals.reopenDeal([formik.values.dealId]);

      onClose(0);
      renderDeals();
      setLoading(false);
      showSnackBar({ type: 'success', message: 'Deal reopened successfully.' });
    } catch (error: any) {
      setLoading(false);
      showSnackBar({ type: 'error', message: 'Unable to reopen deal.' });
      throw new Error('Unable to reopen deal.');
    }
  }

  function handlerOnCloseDrawer() {
    if (JSON.stringify(dealOldValues) != JSON.stringify(formik.values)) {
      Swal.fire({
        showClass: {
          popup: 'animate__animated animate__fadeIn animate__faster',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOut animate__faster',
        },
        width: '37em',
        showCancelButton: true,
        showDenyButton: true,
        confirmButtonColor: '#3F51B5',
        cancelButtonColor: 'grey',
        denyButtonColor: '#FF1744',
        showCloseButton: true,
        reverseButtons: true,
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Save & Close',
        denyButtonText: `Cancel & Close`,
        html: 'There are unsaved changes. Are you sure you want to close?',
      }).then((result) => {
        if (result.isConfirmed) {
          formik.handleSubmit();
        } else if (result.isDenied) {
          onClose(0);
          resetEditDealDrawer();
        }
      });
    } else {
      onClose(0);
      resetEditDealDrawer();
    }
  }

  function handlerOnClickDrawer() {
    if (enableEditDealStage || enableEditDealPipeline || enableEditDealAssignment) {
      setEnableEditDealStage(false);
      setEnableEditDealPipeline(false);
      setEnableEditDealAssignment(false);
    }
  }

  function checkAmount(value: any) {
    //float number and replace other with empty
    const newvalue = value.replace(/[^\.0-9]/g, '');
    if (isNaN(newvalue)) return;
    // Split by the decimal point to validate the integer and fractional parts
    const [integerPart, decimalPart] = newvalue.split('.');
    // Allow a maximum of 12 characters total (integer + fractional parts)
    if (integerPart.length > 12 || (decimalPart && decimalPart.length > 2)) {
      return; // Prevents further input if limits are exceeded
    }
    formik.setFieldValue(`amount`, newvalue);
  }

  const handleDeleteAttachmentsToUpload = (index: number) => {
    const fileName = formik.values.dealAttachmentsToUpload[index].fileName;
    formik.setFieldValue(
      'dealTimelines', formik.values.dealTimelines.filter((i) => i.fileName !== fileName )
    )
    formik.setFieldValue(
      'dealAttachmentsToUpload',
      formik.values.dealAttachmentsToUpload.filter((_, i) => i !== index)
    );
    filesToUpload.splice(index, 1);
  };

    const handleDeleteAttachments = async (index: number, attachment: DealAttachment) => {
      const fileName = formik.values.dealAttachments[index].fileName;
      formik.setFieldValue(
        'dealTimelines', formik.values.dealTimelines.filter((i) => i.fileName !== fileName)
      )
      removedDealAttachments.push(attachment);
      formik.setFieldValue(
        'dealAttachments',
        formik.values.dealAttachments.filter((_, i) => i !== index)
      );
  };

  function renderDealStatusColor(value: number){
    switch(value){
      case DealStatusEnum.Open:
        return '#3f51b5ff'
      case DealStatusEnum.Won:
        return 'Green';
      case DealStatusEnum.Lost:
        return 'Red';
      case DealStatusEnum.Archive:
        return 'Grey';
      default:
        return '#3f51b5ff';
    }
  }
  const editorInit = {
    height: 200,
    min_height: 120,
    ClassNames: 'editor',
    convert_urls: true,
    plugins: 'lists',
    toolbar: `bullist numlist | bold italic underline | alignleft aligncenter alignright alignjustify`,
    menubar: false,
    branding: false,
    force_br_newlines: true,
    force_p_newlines: false,
    forced_root_block: '', // Needed for 3.x
    content_style :formik.values.dealStateId != DealStatusEnum.Open ? 'body {color: #bdbdbd; cursor: not-allowed;}' : ''
  };

  function getDays(): string | undefined {
    if(dealOldValues?.dealCloseDate == null && dealOldValues?.createdDate != null){
      const createdDate = new Date(dealOldValues?.createdDate);
      const now = new Date();
      const diffInTime = now.getTime() - createdDate.getTime();
      const diffInDays = Math.ceil(diffInTime / (1000 * 60 * 60 * 24));
      return diffInDays.toString();
    }
    else if(dealOldValues?.dealCloseDate != null && dealOldValues?.createdDate != null){
      const createdDate = new Date(dealOldValues?.createdDate);
      const closeDate = new Date(dealOldValues?.dealCloseDate);
      const diffInTime = closeDate.getTime() - createdDate.getTime();
      const diffInDays = Math.ceil(diffInTime / (1000 * 60 * 60 * 24));
      return diffInDays.toString();
    }
  }
  
  return (
    <Drawer
      PaperProps={{ style: { width: '960px' } }}
      variant="temporary"
      anchor="right"
      open={open}
      onClick={handlerOnClickDrawer}
      onClose={() => {
        handlerOnCloseDrawer();
      }}>
      <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
        {!loading && (
          <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} padding={2}>
            <Grid container item xs={12} style={{ marginTop: '5px' }}>
              <Grid container item xs={8}>
                <Grid item>
                  <Typography
                    variant="h4"
                    onMouseEnter={() => setHoverTitleAmount(true)}
                    onMouseLeave={() => setHoverTitleAmount(false)}>
                    {!enableEditDealTitle ? (
                      formik.values.title
                    ) : (
                      <>
                        <TextField
                          size="small"
                          variant="outlined"
                          name="title"
                          value={formik.values.title}
                          InputProps={{ classes: { input: classes.editInput1 } }}
                          onChange={formik.handleChange}
                          error={!!formik.errors.title}
                          helperText={formik.errors.title}
                          onBlur={() => {
                            setEnableEditDealTitle(false);
                          }}
                        />
                        <ClearIcon
                          className={classes.clearIcon}
                          onClick={() => {
                            setEnableEditDealTitle(false);
                          }}
                        />
                      </>
                    )}
                    {formik.values.dealStateId === DealStatusEnum.Open && !enableEditDealTitle && (
                      <>
                        {hoverTitleAmount && (
                          <IconButton
                            style={{ marginTop: '-6px' }}
                            onClick={() => {
                              setEnableEditDealTitle(true);
                            }}
                            className={classes.iconButton}>
                            <EditIcon style={{ fontSize: '1rem' }} />
                          </IconButton>
                        )}

                        <span className={classes.errorMsg}>{!!formik.errors.title ? formik.errors.title : ''}</span>
                      </>
                    )}
                  </Typography>
                </Grid>
                <Grid container item xs={12} mt={2}>
                  <Grid container item xs={5}>
                    <Grid item>
                      <WorkIcon className={classes.drawerIcon} />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="h6"
                        onMouseEnter={() => setHoverTitleAmount(true)}
                        onMouseLeave={() => setHoverTitleAmount(false)}>
                        {!enableEditDealAmount ? (
                          'Value: $' +
                          Number(formik.values.amount)?.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                          })
                        ) : (
                          <>
                            <TextField
                              size="small"
                              variant="outlined"
                              name="amount"
                              value={formik.values.amount}
                              InputProps={{ classes: { input: classes.editInput2 } }}
                              onChange={(e: any) => {
                                const value = e.target.value;
                                checkAmount(value);
                              }}
                              error={!!formik.errors.amount}
                              helperText={formik.errors.amount}
                              onBlur={() => {
                                setEnableEditDealAmount(false);
                              }}
                            />
                            <ClearIcon
                              className={classes.clearIcon}
                              onClick={() => {
                                setEnableEditDealAmount(false);
                              }}
                            />
                          </>
                        )}
                        {formik.values.dealStateId === DealStatusEnum.Open && !enableEditDealAmount && (
                          <>
                            {hoverTitleAmount && (
                              <IconButton
                                style={{ marginTop: '-6px' }}
                                onClick={() => {
                                  setEnableEditDealAmount(true);
                                  formik.setFieldValue('amount', Number(formik.values.amount).toFixed(2));
                                }}
                                className={classes.iconButton}>
                                <EditIcon style={{ fontSize: '1rem' }} />
                              </IconButton>
                            )}

                            <span className={classes.errorMsg}>
                              {!!formik.errors.amount ? formik.errors.amount : ''}
                            </span>
                          </>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={7}
                    onMouseEnter={() => setHoverStage(true)}
                    onMouseLeave={() => setHoverStage(false)}>
                    <Grid item style={{ marginTop: enableEditDealStage ? '-10px' : 0 }}>
                      <EmojiEventsIcon className={classes.drawerIcon} />
                      {!enableEditDealStage ? (
                        <Chip
                          title={formik.values.pipelineStageName}
                          style={{
                            marginTop: '-18px',
                            color: 'white',
                            backgroundColor: renderDealStatusColor(formik.values.dealStateId),
                          }}
                          label={
                            formik.values.dealStateId == DealStatusEnum.Open
                              ? formik.values.pipelineStageName.length > 15
                                ? formik.values.pipelineStageName.slice(0, 15).concat('...')
                                : formik.values.pipelineStageName
                              : DealStatusEnum[formik.values.dealStateId]
                          }
                        />
                      ) : (
                        <>
                          <FormControl sx={{ m: 1 }} size="small">
                            <Select
                              variant="outlined"
                              placeholder="Stage"
                              label="Stage"
                              name="stageId"
                              open={enableEditDealStage}
                              value={formik.values.pipelineStageId}
                              style={{ width: '140px', height: '25px' }}
                              onChange={(e: any) => {
                                formik.setFieldValue('pipelineStageId', e.target.value);
                                const stage = pipelineStages?.find((x) => x.stageId === e.target.value);
                                formik.setFieldValue('pipelineStageName', stage?.stageName);
                                formik.setFieldValue('pipelineStagePosition', stage?.stagePosition);
                                setEnableEditDealStage(false);
                              }}
                              onBlur={() => {
                                setEnableEditDealStage(false);
                              }}>
                              {pipelineStages?.map((options) => {
                                return (
                                  <MenuItem key={options.stageId} value={options.stageId}>
                                    {options.stageName}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </>
                      )}
                      {formik.values.dealStateId === DealStatusEnum.Open && !enableEditDealStage && (
                        <>
                          {hoverStage && (
                            <IconButton
                              style={{ marginTop: '-18px' }}
                              onClick={() => {
                                if (!formik.values.assignedUserName) {
                                  showSnackBar({
                                    type: 'error',
                                    message: 'An unassigned deal requires an assignment to move to a new stage.',
                                  });
                                  return;
                                }
                                setTimeout(() => {
                                  setEnableEditDealStage(true);
                                }, 0);
                              }}
                              className={classes.iconButton}>
                              <EditIcon style={{ fontSize: '1rem' }} />
                            </IconButton>
                          )}
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={4} style={{ display: 'block' }}>
                {formik.values.dealStateId != DealStatusEnum.Open ? (
                  <Grid item>
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      className={classes.headerSaveBtn}
                      onClick={handleReopenDeal}>
                      Reopen
                    </Button>
                  </Grid>
                ) : (
                  <>
                    <Grid item>
                      <Button
                        variant="contained"
                        size="large"
                        className={classes.headerWonBtn}
                        onClick={() => setWonDealDrawerState(true)}>
                        Won
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        size="large"
                        className={classes.headerLostBtn}
                        onClick={() => setLostDealDrawerState(true)}>
                        Lost
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        className={classes.headerSaveBtn}
                        type="submit">
                        Save
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid container item xs={9}>
                <Grid
                  container
                  item
                  xs={4}
                  onMouseEnter={() => setHoverAssignment(true)}
                  onMouseLeave={() => setHoverAssignment(false)}
                  style={{paddingBottom:'14px'}}
                  className={formik.values.dealStateId != DealStatusEnum.Open ? classes.disableGray : ''}>
                  <Grid item>
                    <PersonIcon className={classes.drawerIcon} />
                  </Grid>
                  <Grid item>
                      {!enableEditDealAssignment ? (
                        <span>{formik.values.assignedUserName ? formik.values.assignedUserName : 'UNASSIGNED'}</span> ??
                        'UNASSIGNED'
                      ) : (
                          <Grid item>
                            <FormControl size="small">
                              <Select
                                variant="outlined"
                                placeholder="Assignment"
                                label="Assignment"
                                name="assignedUserId"
                                open={enableEditDealAssignment}
                                value={formik.values.assignedUserId}
                                style={{ width: '140px', height: '25px' }}
                                onChange={(e: any) => {
                                  formik.setFieldValue('assignedUserId', e.target.value);
                                  formik.setFieldValue(
                                    'assignedUserName',
                                    pipelineUsers?.find((x) => x.crmUserId == e.target.value)?.username,
                                  );
                                  setEnableEditDealAssignment(false);
                                }}
                                onBlur={() => {
                                  setEnableEditDealAssignment(false);
                                }}>
                                {pipelineUsers?.map((options) => {
                                  return (
                                    <MenuItem key={options.crmUserId} value={options.crmUserId}>
                                      {options.username}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                      )}
                      {formik.values.dealStateId === DealStatusEnum.Open && !enableEditDealAssignment && (
                        <>
                          {hoverAssignment && (
                            <IconButton
                              style={{ marginTop: '-3px' }}
                              onClick={() => {
                                setTimeout(() => {
                                  setEnableEditDealAssignment(true);
                                }, 0);
                              }}
                              className={classes.iconButton}>
                              <EditIcon style={{ fontSize: '1rem' }} />
                            </IconButton>
                          )}
                        </>
                      )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={8}
                  onMouseEnter={() => setHoverPipeline(true)}
                  onMouseLeave={() => setHoverPipeline(false)}
                  style={{paddingLeft: '26px', paddingBottom:'14px' }}>
                  <Grid item>
                    <FilterListIcon className={classes.drawerIcon} />
                  </Grid>
                  {!enableEditDealPipeline ? (
                    <span>{formik.values.pipelineName}</span>
                  ) : (
                    <Grid item>
                      <FormControl size="small">
                        <Select
                          variant="outlined"
                          placeholder="Pipeline"
                          label="Pipeline"
                          name="pipelineId"
                          open={enableEditDealPipeline}
                          style={{ width: '140px', height: '25px' }}
                          value={formik.values.pipelineId}
                          onChange={(e: any) => {
                            formik.setFieldValue('pipelineId', e.target.value);
                            const pipelineName = pipelines?.find((x) => x.pipelineId == e.target.value)?.pipelineName;
                            formik.setFieldValue('pipelineName', pipelineName);
                            setEnableEditDealPipeline(false);
                          }}
                          onBlur={() => {
                            setEnableEditDealPipeline(false);
                          }}>
                          {pipelines?.map((options) => {
                            return (
                              <MenuItem key={options.pipelineId} value={options.pipelineId}>
                                {options.pipelineName}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {formik.values.dealStateId === DealStatusEnum.Open && !enableEditDealPipeline && (
                    <>
                      {hoverPipeline && (
                        <IconButton
                          style={{ marginTop: '-3px' }}
                          onClick={() => {
                            setTimeout(() => {
                              setEnableEditDealPipeline(true);
                            }, 0);
                          }}
                          className={classes.iconButton}>
                          <EditIcon style={{ fontSize: '1rem' }} />
                        </IconButton>
                      )}
                    </>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" flexWrap="wrap" spacing={0.5}>
                    {formik.values.dealStageDays &&
                      formik.values.dealStageDaysObject.map((day) => (
                        <Box mb={1}>
                          <StageDays>
                            {day.numberOfDays + (day.numberOfDays === 0 || day.numberOfDays > 1 ? ' days' : ' day')}
                          </StageDays>
                          <Tooltip title={day.dealStageName.toUpperCase()}>
                            <Typography style={{ fontWeight: 'bold', width: '65px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                              {day.dealStageName.length > 9
                                ? day.dealStageName.toUpperCase().substring(0, 8) + '...'
                                : day.dealStageName.toUpperCase()}
                            </Typography>
                          </Tooltip>
                        </Box>
                      ))}
                  </Stack>
                </Grid>
              </Grid>
              <Grid container item xs={3}>
                <Grid item xs={8}>
                  <Typography variant="h5" style={{ display: 'flex', alignItems: 'center' }}>
                    <DaysOpenIcon /> Days Open:{' '}
                  </Typography>
                  <Typography variant="h5" style={{ display: 'flex', alignItems: 'center' }}>
                    <DealsCalendarIcon /> Date Originated:{' '}
                  </Typography>
                  <Typography variant="h5" style={{ display: 'flex', alignItems: 'center' }}>
                    <DealsCalendarIcon />
                    {dealOldValues?.dealStateId && dealOldValues?.dealStateId !== DealStatusEnum.Archive
                      ? 'Date Closed: '
                      : 'Date Archived: '}
                  </Typography>
                </Grid>
                <Grid style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }} item xs={4}>
                  <Typography
                    variant="h5"
                    style={{ backgroundColor: '#ff0000c9', color: 'white', borderRadius: '3px' }}>
                    {' '}
                    {getDays()}
                  </Typography>
                  <Typography
                    variant="h5"
                    style={{ backgroundColor: '#008000bd', color: 'white', borderRadius: '3px', marginTop: '5px' }}>
                    {' '}
                    {dealOldValues?.createdDate ? format(new Date(dealOldValues?.createdDate), 'M/dd/yy') : ''}
                  </Typography>
                  {dealOldValues?.dealStateId && dealOldValues?.dealStateId !== DealStatusEnum.Archive ? (
                    <Typography
                      variant="h5"
                      style={{ backgroundColor: 'lightgray', borderRadius: '3px', marginTop: '5px' }}>
                      {' '}
                      {dealOldValues?.dealCloseDate ? format(new Date(dealOldValues?.dealCloseDate), 'M/dd/yy') : 'N/A'}
                    </Typography>
                  ) : (
                    <Typography
                      variant="h5"
                      style={{ backgroundColor: 'lightgray', borderRadius: '3px', marginTop: '5px' }}>
                      {' '}
                      {dealOldValues?.dealArchiveDate
                        ? format(new Date(dealOldValues?.dealArchiveDate), 'M/dd/yy')
                        : 'N/A'}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid style={{ paddingTop: '0px' }} container item xs={12}>
              <Grid item xs={9}>
                <FormControlLabel
                  style={{ marginLeft: '0px' }}
                  control={
                    <Switch
                    checked={formik.values.isTaxExempted}
                    onChange={(event) => {
                      formik.setFieldValue('isTaxExempted', event.target.checked);
                    }}
                    name="isTaxExempted"
                    color="primary"
                    />
                  }
                  label={<Typography style={{ fontWeight: 'bold' }}>Tax Exempt</Typography>}
                  labelPlacement="start"
                  />
              </Grid>
              <Grid item xs={3}>
                <Typography variant="h5" style={{display:'flex', alignItems:'center', justifyContent:'flex-start', paddingLeft:'1px'}}>
                  <GroupsIcon style={{paddingRight:'5px'}}/> 
                  {dealOldValues?.dealType}
                </Typography>
              </Grid>
            </Grid>
            <Grid style={{ paddingTop: '0px' }} container item xs={12}>
              <Grid item xs={7} padding={1}>
                <Typography variant="h5">Description</Typography>
                <Editor
                  
                  tagName="dealDescriptionEdit+or"
                  apiKey={textEditorApiKey ||''}
                  id="tinyDealDescription"
                  disabled={formik.values.dealStateId != DealStatusEnum.Open}
                  value={formik.values.description}
                  onEditorChange={(content: any) => {
                    formik.setFieldValue('description', content);
                  }}
                  init={editorInit}>
                  </Editor>
                <hr />
                <CustomerContactsList
                  formik={formik}
                  selectedContacts={selectedContacts}
                  selectedCustomers={selectedCustomers}
                  customerTreeOptions={customerTreeOptions}
                  newCustomerContact={newCustomerContact}
                  enableCustomerValidation={enableCustomerValidation}
                  setSelectedContacts={setSelectedContacts}
                  setSelectedCustomers={setSelectedCustomers}
                  setCustomerTreeOptions={setCustomerTreeOptions}
                  setCustomerContactDrawerState={setCustomerContactDrawerState}
                  setEnableCustomerValidation={setEnableCustomerValidation}
                />
                <hr style={{ marginTop: '20px' }} />
                <DealProductsList formik={formik} />
                <hr style={{ marginTop: '20px' }} />
                <DealAttachmentsChips
                  formik={formik}
                  handleDeleteAttachments={handleDeleteAttachments}
                  handleDeleteAttachmentsToUpload={handleDeleteAttachmentsToUpload}
                />

                <hr style={{ marginTop: '30px' }} />
                {formik.values.dealStateId === DealStatusEnum.Open && (
                  <Grid container item xs={12}>
                    <Grid item>
                      <Button
                        variant="contained"
                        size="large"
                        className={classes.footerArchiveBtn}
                        onClick={() => setArchiveDealDrawerState(true)}>
                        <FontAwesomeIcon icon={faArchive} className={classes.drawerIcon} />
                        Archive
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={5} id="tabsWindow" className={classes.drawerRightWindow}>
                <DealEditDrawerTabs
                  formik={formik}
                  taskTypes={taskTypes}
                  taskOutcomes={taskOutcomes}
                  dealerUsers={dealerUsers}
                  filesToUpload={filesToUpload}
                  removedDealAttachments={removedDealAttachments}
                />
              </Grid>
            </Grid>

            <LostDealDrawer
              open={lostDealDrawerState}
              editCount={1}
              selectedDeals={[dealItem]}
              renderDeals={renderDeals}
              onClose={() => {
                setLostDealDrawerState(false);
                onClose(0);
              }}
            />

            <WonDealDrawer
              open={wonDealDrawerState}
              editCount={1}
              selectedColumn={[dealItem]}
              renderDeals={renderDeals}
              onClose={() => {
                setWonDealDrawerState(false);
                onClose(0);
              }}
            />

            <ArchiveDealDrawer
              open={archiveDealDrawerState}
              editCount={1}
              selectedColumn={[dealItem]}
              renderDeals={renderDeals}
              onClose={() => {
                setArchiveDealDrawerState(false);
                onClose(0);
              }}
            />

            <CustomerContactDrawer
              open={customerContactDrawerState}
              selectedCustomers={selectedCustomers}
              setNewCustomerContact={setNewCustomerContact}
              onClose={() => setCustomerContactDrawerState(false)}
              convPhoneIds={undefined}
            />

            {<CustomerRootPageDrawer />}
            {<CustomerRootDrawer />}
          </Grid>
        )}
        {loading && (
          <Grid justifyContent="center" container marginTop="40%">
            <CircularProgress />
          </Grid>
        )}
      </form>
    </Drawer>
  );
}

let selectedIndex = -1;
function DealProductsList({ formik }: { formik: any}): ReactElement {
  const classes: any = useStyles();
  let initialValues: DealProduct = {
    id: 0,
    conditionId: 1,
    year: '',
    make: '',
    model: '',
    vinSerialNo: '',
    stockNo: '',
    description: '',
    price: 0,
    class: '',
    category: '',
    trim:'',
    color:'',
    accessories:'',
    miscellaneousDescription: '',
    miscellaneousPrice: 0,
    isUpdated: false,
    isDeleted: false,
  };

  const [popoverState, setPopoverState] = useState<any>({ openedPopover: false, anchorEl: null });
  const [productCondtions, setProductCondtions] = useState<ProductCondition[]>([]);
  const [hoverProducts, setHoverProducts] = useState(false);
  const [prodFormType, setProdFormType] = React.useState("Simple");
  const [totalProdPrice, setTotalProdPrice] = React.useState<Number>(0);
  const schema = Yup.object<any>({
    price: Yup.number().required('Price Required'),
    miscellaneousPrice: Yup.number().required('Price Required'),
  });

  const formikProduct = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: (product: DealProduct) => {
      updateProductList();
    },
  });

  React.useEffect(() => {
    getProductConditionsList();
    calculateProductPrice();
  }, []);

  const calculateProductPrice=()=>{
    const dealProducts = [...formik.values.dealProducts];
    let sumOfProdPrice:Number = Number(Number(dealProducts.filter(item => !item.isDeleted).reduce((sum, item) => sum + item.price + item.miscellaneousPrice, 0)).toFixed(2)); 
    setTotalProdPrice(sumOfProdPrice);
    return sumOfProdPrice;
  }
  const getProductConditionsList = React.useCallback(async (): Promise<void> => {
    const data = await DealDetailsAPI.getProductConditions();
    setProductCondtions(data);
  }, [1]);

  const handlePopoverOpen = (event: any) => {
    setPopoverState({
      openedPopover: true,
      anchorEl: event.target,
    });
  };

  const handlePopoverClose = () => {
    formikProduct.resetForm();
    // setProdFormType('Simple')
    selectedIndex = -1;
    setPopoverState({
      openedPopover: false,
      anchorEl: null,
    });
  };

  const showEditProductPopOver = (event: any, index: number) => {
    selectedIndex = index;
    const dealProducts = [...formik.values.dealProducts];
    dealProducts[selectedIndex].price = Number(Number(dealProducts[selectedIndex].price).toFixed(2));
    formikProduct.setValues(dealProducts[selectedIndex]);
    handlePopoverOpen(event);
  };

  const updateProductList = () => {
    let dealProducts = [...formik.values.dealProducts];
    formikProduct.values.price=Number(formikProduct.values.price);
    formikProduct.values.miscellaneousPrice=Number(formikProduct.values.miscellaneousPrice);
    if(!formikProduct.values?.miscellaneousDescription && formikProduct.values?.miscellaneousDescription?.length==0 && Number(formikProduct.values.miscellaneousPrice)==0  && formikProduct.values.description?.length==0){
      formikProduct.setErrors({description:'Description Required'})
      return
    }
    if(Number(formikProduct.values?.price)!=0 && formikProduct.values?.description?.length==0){
      formikProduct.setErrors({description:'Description Required'})
      return
    }
    if(formikProduct.values?.miscellaneousDescription && formikProduct.values?.miscellaneousDescription?.length!=0 && Number(formikProduct.values.miscellaneousPrice)==0){
      formikProduct.setErrors({miscellaneousPrice:'Price is Required'})
      return
    }else if(Number(formikProduct.values.miscellaneousPrice)!=0 && formikProduct.values?.miscellaneousDescription?.length==0){
      formikProduct.setErrors({miscellaneousDescription:'Description is Required'})
      return
    }
    if(formikProduct.values?.description=='' || !formikProduct.values?.description)
      formikProduct.values.conditionId=0;

    const indexOfMicellaneous = dealProducts.findIndex(item => item.conditionId === 0);
    if (selectedIndex == -1) {
      if(indexOfMicellaneous!==-1 && formikProduct.values.conditionId!=0 ){
        dealProducts.splice(indexOfMicellaneous,0,formikProduct.values);
      }else{
        dealProducts.push(formikProduct.values);
      }
    } else {
      dealProducts[selectedIndex] = formikProduct.values;
      dealProducts[selectedIndex].isUpdated = true;
      setPopoverState({
        openedPopover: false,
        anchorEl: null,
      });
      selectedIndex = -1;
    }
    
    formik.setFieldValue('dealProducts', dealProducts);
    let sumOfProdPrice:Number = Number(Number(dealProducts.filter(item => !item.isDeleted).reduce((sum, item) => sum + item.price + item.miscellaneousPrice, 0)).toFixed(2)); 
    setTotalProdPrice(sumOfProdPrice);
    formik.setFieldValue('amount',sumOfProdPrice);
    handlePopoverClose();
  };

  const removeFromProductList = (index: number) => {
    const dealProducts = [...formik.values.dealProducts];
    dealProducts[index].isDeleted = true;
    formik.setFieldValue('dealProducts', dealProducts);
    let sumOfProdPrice:Number = Number(Number(dealProducts.filter(item => !item.isDeleted).reduce((sum, item) => sum + Number(item.price) + Number(item.miscellaneousPrice), 0)).toFixed(2)); 
    formik.setFieldValue('amount',sumOfProdPrice);
    setTotalProdPrice(sumOfProdPrice);
  };

  function setProductPrice(value: any) {
    if (isNaN(value)) return;
    if (value.toString().indexOf('.') > -1 && value.toString().split('.')[1].length > 2) return;
    formikProduct.setFieldValue(`price`, value);
  }

  const handleProductFormChange = (event:any, newValue:any) => {
    setProdFormType(newValue);
  };

  return (
    <Grid container spacing={1} justifyContent="space-between">
      <Grid item>
        <Grid container>
          <Grid item>
            <ShoppingCartIcon className={classes.drawerIcon} />
          </Grid>
          <Grid item>
            <Typography variant="h5">Products</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {formik.values.dealStateId === DealStatusEnum.Open && (
          <Typography variant="h5">
            <AddIcon onClick={(e: any) => handlePopoverOpen(e)} style={{ cursor: 'pointer' }} />
          </Typography>
        )}
      </Grid>
      <Grid
        container
        onMouseEnter={() => setHoverProducts(true)}
        onMouseLeave={() => setHoverProducts(false)}
        style={{ marginTop: 2, marginLeft: 1 }}
        className={formik.values.dealStateId != DealStatusEnum.Open ? classes.disableGray : ''}>
        {formik.values.dealProducts &&
          formik.values.dealProducts.map(
            (product: any, index: number) =>
              !product.isDeleted && (
                <>
                  <Grid key={index} container item mb={1}>
                    <Grid container justifyContent={'space-between'} style={{ marginLeft: '12px' }}>
                      {product?.description != '' &&  (
                        <Grid item xs={9}>
                          <Typography variant="h6">
                            {productCondtions.find((x) => x.id == product.conditionId)?.name +
                              ' ' +
                              (product?.year ?? '') +
                              ' ' +
                              (product?.make ?? '') +
                              ' ' +
                              (product?.model ?? '') +
                              ' ' +
                              (product?.vinSerialNo ?? '') +
                              ' ' +
                              (product?.stockNo ?? '') +
                              ' ' +
                              (product?.class ?? '') +
                              ' ' +
                              (product?.category ?? '') +
                              ' ' +
                              (product?.trim ?? '') +
                              ' ' +
                              (product?.color ?? '') +
                              ' ' +
                              (product?.accessories && product?.accessories?.length <= 15
                                ? product?.accessories
                                : product?.accessories
                                ? product?.accessories?.substring(0, 15) + '...'
                                : '') +
                              ' ' +
                              (product?.description === 'N/A' 
                                ? '' // Don't show anything
                                : (product?.description?.length <= 15 
                                    ? product?.description
                                    : product?.description?.substring(0, 15) + '...'))
                            }
                            {/* Editing and Delete Button */}
                            {Number(product?.miscellaneousPrice) == 0 &&
                              formik.values.dealStateId === DealStatusEnum.Open &&
                              (popoverState.openedPopover || hoverProducts) && (
                                <>
                                  <IconButton
                                    style={{ marginTop: '-10px' }}
                                    aria-describedby={index.toString()}
                                    onClick={(e: any) => showEditProductPopOver(e, index)}
                                    className={classes.iconButton}>
                                    <EditIcon style={{ fontSize: '1rem' }} />
                                  </IconButton>
                                  <ClearIcon
                                    className={classes.clearSubIcon}
                                    onClick={() => {
                                      removeFromProductList(index);
                                    }}
                                  />
                                </>
                              )}
                          </Typography>
                        </Grid>
                      )}
                      {Number(product?.price) != 0 && (
                        <Grid item xs={3}>
                          <Typography style={{float:'right', paddingRight:'8px'}}>
                            {'$' + Number(product?.price).toLocaleString('en-US', { minimumFractionDigits: 2 })}
                          </Typography>
                        </Grid>
                      )}
                      {Number(product?.miscellaneousPrice) != 0 && (
                        <Grid container justifyContent={'space-between'} >
                          <Grid item xs={9}>
                            <Typography variant="h6">
                              {product?.miscellaneousDescription?.length <= 15
                                ? product?.miscellaneousDescription
                                : product?.miscellaneousDescription?.substring(0, 15) + '...'}
                              {formik.values.dealStateId === DealStatusEnum.Open &&
                                (popoverState.openedPopover || hoverProducts) && (
                                  <>
                                    <IconButton
                                      style={{ marginTop: '-10px' }}
                                      aria-describedby={index.toString()}
                                      onClick={(e: any) => showEditProductPopOver(e, index)}
                                      className={classes.iconButton}>
                                      <EditIcon style={{ fontSize: '1rem' }} />
                                    </IconButton>
                                    <ClearIcon
                                      className={classes.clearSubIcon}
                                      onClick={() => {
                                        removeFromProductList(index);
                                      }}
                                    />
                                  </>
                                )}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography style={{float:'right',paddingRight:'8px'}}>
                              {'$' +
                                Number(product.miscellaneousPrice).toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                })}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </>
              ),
          )}
        {formik.values.dealProducts.filter((x: any) => !x.isDeleted).length > 0 && (
          <>
            <Divider style={{ width: '100%' }} />
            <Grid container justifyContent={'right'}>
                <Typography style={{paddingRight:'8px'}}>
                  Total Price {'$' + Number(totalProdPrice).toLocaleString('en-US', { minimumFractionDigits: 2 })}
                </Typography>
            </Grid>
          </>
        )}
        <Popover
          id={'popOverProduct'}
          open={popoverState.openedPopover}
          anchorEl={popoverState.anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}>
          <form noValidate autoComplete="off" onSubmit={formikProduct.handleSubmit}>
            <Box p={2} style={{ width: '620px', left: '' }}>
              <Tabs
                indicatorColor="primary"
                TabIndicatorProps={{
                  style: {
                    color: '#3f50b5',
                  },
                }}
                value={prodFormType}
                onChange={handleProductFormChange}
                aria-label="Product tabs"
                variant="fullWidth">
                <Tab label="Simple" value={'Simple'} style={{ minWidth: '50%', color: '#3f50b5' }} />
                <Tab label="Advanced" value={'Advanced'} style={{ minWidth: '50%', color: '#3f50b5' }} />
              </Tabs>
              <ProductForm
                setProductPrice={setProductPrice}
                formikProduct={formikProduct}
                productCondtions={productCondtions}
                prodFormType={prodFormType}
              />
              <Grid container style={{ display: 'block', marginTop: '10px' }}>
                <Grid item style={{ float: 'right' }}>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    type="submit"
                    style={{ marginRight: '0.5rem' }}>
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={async (e: any) => {
                      await updateProductList();
                      await handlePopoverOpen(e);
                    }}
                    style={{ cursor: 'pointer' }}>
                    Save + Add More
                  </Button>
                </Grid>
                <Grid item style={{ paddingRight: '2%' }}>
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={() => {
                      handlePopoverClose();
                    }}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Popover>
      </Grid>
    </Grid>
  );
}
function ProductForm({
  setProductPrice,
  formikProduct,
  productCondtions,
  prodFormType,
}: {
  setProductPrice: any;
  formikProduct: any;
  productCondtions: any;
  prodFormType: string;
}) {

  const  isWGDisabled  = Number(formikProduct.values.miscellaneousPrice) !== 0 || formikProduct.values?.miscellaneousDescription?.length !== 0;
  const isMiscellaneousFeildDisabled =
    Number(formikProduct.values?.price) != 0 ||
    formikProduct.values?.description?.length != 0 ||
    formikProduct.values?.year?.length != 0 ||
    formikProduct.values?.make?.length != 0 ||
    formikProduct.values?.model?.length != 0 ||
    formikProduct.values?.vinSerialNo?.length != 0 ||
    formikProduct.values?.stockNo?.length != 0 ||
    formikProduct.values?.class?.length != 0 ||
    formikProduct.values?.category?.length != 0 ||
    formikProduct.values?.trim?.length != 0 ||
    formikProduct.values?.color?.length != 0 ||
    formikProduct.values?.accessories?.length != 0;
    
  return (
    <Grid container justifyContent="space-between"  marginTop={'5px'}>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={3}>
          <TextField
            disabled={isWGDisabled}
            select
            fullWidth
            variant="outlined"
            margin="dense"
            label="Condition"
            placeholder="Condition"
            name="conditionId"
            value={formikProduct.values.conditionId}
            onChange={formikProduct.handleChange}>
            {productCondtions?.map((x: any) => (
              <MenuItem key={x.id} value={x.id}>
                {x.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={3}>
          <TextField
            disabled={isWGDisabled}
            fullWidth
            variant="outlined"
            margin="dense"
            label="Year"
            name="year"
            inputProps={{ maxLength: 50 }}
            value={formikProduct.values.year}
            onChange={formikProduct.handleChange}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            disabled={isWGDisabled}
            fullWidth
            variant="outlined"
            margin="dense"
            label="Make"
            name="make"
            inputProps={{ maxLength: 50 }}
            value={formikProduct.values.make}
            onChange={formikProduct.handleChange}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            disabled={isWGDisabled}
            fullWidth
            variant="outlined"
            margin="dense"
            label="Model"
            name="model"
            inputProps={{ maxLength: 50 }}
            value={formikProduct.values.model}
            onChange={formikProduct.handleChange}
          />
        </Grid>
      </Grid>
      {prodFormType != 'Simple' && (
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={3}>
            <TextField
              disabled={isWGDisabled}
              fullWidth
              variant="outlined"
              margin="dense"
              label="Class"
              name="class"
              inputProps={{ maxLength: 50 }}
              value={formikProduct.values.class}
              onChange={formikProduct.handleChange}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              disabled={isWGDisabled}
              fullWidth
              variant="outlined"
              margin="dense"
              label="Category"
              name="category"
              inputProps={{ maxLength: 50 }}
              value={formikProduct.values.category}
              onChange={formikProduct.handleChange}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              disabled={isWGDisabled}
              fullWidth
              variant="outlined"
              margin="dense"
              label="Trim"
              name="trim"
              inputProps={{ maxLength: 50 }}
              value={formikProduct.values.trim}
              onChange={formikProduct.handleChange}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              disabled={isWGDisabled}
              fullWidth
              variant="outlined"
              margin="dense"
              label="Color"
              name="color"
              inputProps={{ maxLength: 50 }}
              value={formikProduct.values.color}
              onChange={formikProduct.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={isWGDisabled}
              fullWidth
              variant="outlined"
              margin="dense"
              label="Accessories"
              name="accessories"
              inputProps={{ maxLength: 50 }}
              value={formikProduct.values.accessories}
              onChange={formikProduct.handleChange}
            />
          </Grid>
        </Grid>
      )}

      <Grid container item xs={12} spacing={1}>
        <Grid item xs={9}>
          <TextField
            disabled={isWGDisabled}
            fullWidth
            variant="outlined"
            margin="dense"
            label="VIN/Serial No."
            name="vinSerialNo"
            inputProps={{ maxLength: 100 }}
            value={formikProduct.values.vinSerialNo}
            onChange={formikProduct.handleChange}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            disabled={isWGDisabled}
            fullWidth
            variant="outlined"
            margin="dense"
            label="Stock Number"
            name="stockNo"
            inputProps={{ maxLength: 50 }}
            value={formikProduct.values.stockNo}
            onChange={formikProduct.handleChange}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={9}>
          <TextField
            disabled={isWGDisabled}
            fullWidth
            variant="outlined"
            margin="dense"
            label="Description"
            name="description"
            inputProps={{ maxLength: 200 }}
            value={formikProduct.values.description}
            onChange={formikProduct.handleChange}
            error={!!formikProduct.errors.description}
            helperText={formikProduct.errors.description}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            disabled={isWGDisabled}
            fullWidth
            variant="outlined"
            margin="dense"
            label="Price"
            name="price"
            value={formikProduct.values.price}
            onChange={(e: any) => {
              setProductPrice(e.target.value);
            }}
            error={!!formikProduct.errors.price}
            helperText={formikProduct.errors.price}
          />
        </Grid>
      </Grid>
      <Divider style={{ width: '100%', marginTop: '0.5rem', marginBottom: '0.5rem' }} />
      <Typography variant="h4">Miscellaneous</Typography>
      <Grid container spacing={1} item xs={12}>
        <Grid item xs={9} >
          <TextField
            disabled={isMiscellaneousFeildDisabled}
            fullWidth
            variant="outlined"
            margin="dense"
            label="Fee/Item Description"
            name="miscellaneousDescription"
            inputProps={{ maxLength: 200 }}
            value={formikProduct.values.miscellaneousDescription}
            onChange={formikProduct.handleChange}
            error={!!formikProduct.errors.miscellaneousDescription}
            helperText={formikProduct.errors.miscellaneousDescription}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            disabled={isMiscellaneousFeildDisabled}
            fullWidth
            variant="outlined"
            margin="dense"
            label="Price"
            name="miscellaneousPrice"
            value={formikProduct.values.miscellaneousPrice}
            onChange={(e: any) => {
              let value = e.target.value;
              if (isNaN(value)) return;
              if (value.toString().indexOf('.') > -1 && value.toString().split('.')[1].length > 2) return;
              formikProduct.setFieldValue(`miscellaneousPrice`, value);
            }}
            error={!!formikProduct.errors.miscellaneousPrice}
            helperText={formikProduct.errors.miscellaneousPrice}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

function DealAttachmentsChips({
  formik,
  handleDeleteAttachments,
  handleDeleteAttachmentsToUpload
}: {
  formik: any;
  handleDeleteAttachments: any;
  handleDeleteAttachmentsToUpload: any;
}): ReactElement {
  const classes: any = useStyles();
  const [imageSrc, setImageSrc] = React.useState<string | undefined>(undefined);
  const [selectedPdf, setSelectedPdf] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>('');

  const getIconByExtension = (extension: string) => {
    if (extension === 'pdf') {
      return <PDFIcon />;
    }else if (['xlsx', 'xls'].includes(extension)) {
      return <TextSnippetIcon />;
    }else if (['png', 'jpg', 'jpeg', 'gif'].includes(extension)) {
      return <ImageIcon />;
    }
    return null;
  };

  const openPdf = (isOpen: boolean, url: any, fileName: any) => {
        setOpenModal(isOpen);
        setSelectedPdf(url);
        setFileName(fileName);
  }
  return (
    <Grid>
    <Grid item>
      <Grid container>
        <Grid item>
          <DescriptionIcon className={classes.drawerIcon} />
        </Grid>
        <Grid item style={{ marginBottom: '16px' }}>
          <Typography variant="h5">Files</Typography>
        </Grid>
      </Grid>
    </Grid>
    <ImageModal open={imageSrc !== undefined} imageSrc={imageSrc} onClose={(): void => setImageSrc(undefined)} />

    {formik.values.dealAttachments.map((dealAttachment: DealAttachment, index: any) => (
      <Chip
        key={index}
        size="medium"
        label={
          <div style={{ display: 'flex', alignItems: 'center' }}>
        {getIconByExtension(getFileExtension(dealAttachment.fileURL))}
          <span style={{ padding: '0 8px' }}>{dealAttachment.fileName}</span>
        </div>
        }
        variant="default"
        style={{
          margin: '0 8px 8px 0',
          height: '36px',
        }}
        color="primary"
        onClick={() => {
          const extension = getFileExtension(dealAttachment.fileURL)
          if(extension == 'xlsx' || extension == 'xls'){
            const link = document.createElement('a');
            link.href = dealAttachment.fileURL;
            link.download = dealAttachment.fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); 
          }
          else if (extension != 'pdf') {
          setImageSrc(dealAttachment.fileURL)
          }
          else {
            openPdf(true, dealAttachment.fileURL, dealAttachment.fileName)
          }
        }}
        onDelete={formik.values.dealStateId === 1 ? () => handleDeleteAttachments(index, dealAttachment) : undefined}
      />
    ))}

    {formik.values.dealAttachmentsToUpload.map((dealAttachment: any, index: any) => (
      <Chip
        key={index}
        size="medium"
        label={
          <div style={{ display: 'flex', alignItems: 'center' }}>
        {getIconByExtension(getFileExtension(dealAttachment.fileURL))}
          <span style={{ padding: '0 8px' }}>{dealAttachment.fileName}</span>
        </div>
        }        
        variant="default"
        style={{
          margin: '0 8px 8px 0',
          height: '36px'
        }}
        color="primary"
        onClick={() => {
          const extension = getFileExtension(dealAttachment.fileURL)
          if(extension == 'xlsx' || extension == 'xls'){
            const link = document.createElement('a');
            link.href = dealAttachment.fileURL;
            link.download = dealAttachment.fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
          else if (extension != 'pdf') {
          setImageSrc(dealAttachment.fileURL)
          }
          else {
            openPdf(true, dealAttachment.fileURL, dealAttachment.fileName)
          }
        }}
        onDelete={() => {
          handleDeleteAttachmentsToUpload(index);
        }}
      />
    ))}

{selectedPdf && (
  <PdfPreviewModal 
  openModal = {openModal}
  openPdf = {openPdf}
  fileName= {fileName}
  selectedPdf={selectedPdf}
  />
  )}
  </Grid>
  );
}
function CustomerContactsList({
  formik,
  selectedContacts,
  selectedCustomers,
  customerTreeOptions,
  newCustomerContact,
  enableCustomerValidation,
  setSelectedContacts,
  setSelectedCustomers,
  setCustomerTreeOptions,
  setCustomerContactDrawerState,
  setEnableCustomerValidation,
}: {
  formik: any;
  selectedContacts: number[];
  selectedCustomers: CustomerDto[] | undefined;
  customerTreeOptions: CustomerTreeOption[];
  newCustomerContact: CustomerContacResponse | null;
  enableCustomerValidation: boolean;
  setSelectedContacts(contacts: number[]): void;
  setSelectedCustomers(customers: CustomerDto[] | undefined): void;
  setCustomerTreeOptions(options: CustomerTreeOption[]): void;
  setCustomerContactDrawerState(value: boolean): void;
  setEnableCustomerValidation(value: boolean): void;
}): ReactElement {
  const classes: any = useStyles();
  const [nodeIdCount, setNodeIdCount] = useState<number>(0);
//   let userPermissions = useAppSelector((state) => state.user?.userPermissions);
//   let isCustomerPermission = 0;
//   if (!userPermissions) {
//     userPermissions = [];
//     isCustomerPermission = 2;
//   }
//  if (isCustomerPermission == 0) {
//   if (userPermissions.find((x) => x.featureName.toLowerCase() === 'customers')) {
//     isCustomerPermission = 1;
//  } 
// }

  React.useEffect(() => {
    if (newCustomerContact != null) {
      setNewContactForAlreadySelectedCustomer(newCustomerContact);
    }
  }, [newCustomerContact]);

  function setNewContactForAlreadySelectedCustomer(newCustomerContact: CustomerContacResponse) {
    let customerDD = selectedCustomers?.find((x) => x.id == newCustomerContact.crmCustomerId);

    if (customerDD) {
      let updatedCustomerTreeOptions = [...customerTreeOptions];

      let customerExists = customerTreeOptions.find((x) => x.value == newCustomerContact.crmCustomerId);
      const lastCustomerContacts =
        customerTreeOptions.length > 0 ? customerTreeOptions[customerTreeOptions.length - 1].nodes : undefined;
      let nodeId = lastCustomerContacts ? lastCustomerContacts[lastCustomerContacts.length - 1].nodeId : 0;

      if (customerExists) {
        customerExists.nodes?.push({
          type: 'contact',
          title: newCustomerContact.name,
          value: newCustomerContact.id,
          nodeId: ++nodeId,
          customerId: newCustomerContact.crmCustomerId,
        });

        const customerOptionIndex = customerTreeOptions.findIndex((x) => x.value == newCustomerContact.crmCustomerId);
        updatedCustomerTreeOptions[customerOptionIndex] = customerExists;
        setCustomerTreeOptions(updatedCustomerTreeOptions);
        setNodeIdCount(nodeId);
      } else {
        updatedCustomerTreeOptions.push({
          type: 'customer',
          title: customerDD.name,
          value: newCustomerContact.crmCustomerId,
          nodeId: ++nodeId,
          nodes: [
            {
              type: 'contact',
              title: newCustomerContact.name,
              value: newCustomerContact.id,
              nodeId: ++nodeId,
              customerId: newCustomerContact.crmCustomerId,
            },
          ],
        });
        setCustomerTreeOptions(updatedCustomerTreeOptions);
        setNodeIdCount(nodeId);
      }
    }
  }

  return (
      <Grid container spacing={1} justifyContent="space-between">
      <Grid item>
        <Grid container>
          <Grid item>
            <PersonIcon className={classes.drawerIcon} />
          </Grid>
          <Grid item>
            <Typography variant="h5">Customer Contacts</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container ml={1}>
        <Grid container item xs={12} ml={1}>
          <CustomerContactTreeView
            dealCustomerContacts={formik.values.dealCustomerContacts}
            selectedContacts={selectedContacts}
            selectedCustomers={selectedCustomers}
            customerTreeOptions={customerTreeOptions}
            enableCustomerValidation={enableCustomerValidation}
            nodeIdCount={nodeIdCount}
            disabledTreeView={formik.values.dealStateId != DealStatusEnum.Open}
            setSelectedContacts={setSelectedContacts}
            setSelectedCustomers={setSelectedCustomers}
            setCustomerTreeOptions={setCustomerTreeOptions}
            setCustomerContactDrawerState={setCustomerContactDrawerState}
            setNodeIdCount={setNodeIdCount}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default DealEditDrawer;
