import { Box, Chip, Typography, withStyles } from '@material-ui/core';
import useLocations from 'common/hooks/useLocations';
import { formatPhoneNumber, isNotNullOrUndefined } from 'common/utils';
import React from 'react';
import { useAppSelector } from 'app';

import { FilterItem } from '../../../models/filter';
import { InvoiceOptions } from '../filter-drawer';
import { NoSaleOptions } from '../filter-drawer';
import { PeriodOptions } from '../filter-drawer';

const ClickAbleChip = withStyles({
  root: {
    cursor: 'pointer',
  },
 
})(Chip);

const ClickAbleWrapChip = withStyles({
  root: {
    cursor: 'pointer',
    height: 'auto',
    paddingTop: '4px',
    paddingBottom: '4px',
    minHeight:'32px'
  },
  label: {
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },
})(Chip);

export default function FilterChip({
  filter,
  onDelete,
  onClick,
  getCustomerCategoryName,
  getManufacturerCode,
  CustomerCategoryReadOnly,
}: {
  filter: FilterItem;
  CustomerCategoryReadOnly: boolean;
  onClick(event: any): void;
  getCustomerCategoryName(id: number): string;  
  getManufacturerCode(id: string): string;
  onDelete(filterItem: FilterItem): void;
}): React.ReactElement | null {
  function handleOnDelete(event: any): void {
    onDelete(filter);
  }
  const { data }: any = filter;
  const salesStages = ['Select Sales Stage', 'Lead', 'Marketing Qualified Lead', 'Sales Qualified Lead', 'Active Opportunity', 'Customer']
  let content = null;
  if (filter.type === 'PeriodFilter') {
    if (isNotNullOrUndefined(data.period) && isNotNullOrUndefined(data.periodValue)) {
      const option = PeriodOptions.find((op) => op.value === data.period);
      // content = <ClickAbleChip label={option?.formatLabel(data.periodValue)}  />;
      content = <ClickAbleChip label={option?.formatLabel(data.periodValue, data.fromMonth) }   />;
    }
  }
  if (filter.type === 'InvoiceTypeFilter') {
    if (isNotNullOrUndefined(data.invoiceTypes)) {
      let invoiceLabels: any[] = []
      data.invoiceTypes.forEach((invoiceType: any) => {
        if (invoiceType.value !== 5) {
        invoiceLabels.push(invoiceType.label)
        }
      })
      if (invoiceLabels.length >= 3) {
        let label = `All Invoices`;
        content = <ClickAbleChip label={label} />;
      } else if (invoiceLabels.length !== 0) {
      const label = <NestedChip label="Invoice Type" subLabels={invoiceLabels} />;
      content = <ClickAbleChip style={{height:'auto', paddingTop:'4px',paddingBottom:'4px'}} label={label} />;
      }
    }
  }
  if (filter.type === 'NoInvoice') {
    let label = `No Invoice`;
    content = <ClickAbleChip label={label} />;
  } 
  if (filter.type === 'StateFilter') {
    if (isNotNullOrUndefined(data.states) && data.states.length) {
      const label = <NestedChip label="States" subLabels={data.states} />;
      content = <ClickAbleChip style={{height:'auto', paddingTop:'4px',paddingBottom:'4px'}} label={label} onDelete={handleOnDelete} />;
    }
  }
  if (filter.type === 'PhoneNumberFilter') {
    if (isNotNullOrUndefined(data.phoneNumber) && data.phoneNumber.length) {
      const label = <NestedChip label="Phone Number" subLabels={data.phoneNumber} IsPhone={true}/>;
      content = <ClickAbleChip style={{height:'auto', paddingTop:'4px',paddingBottom:'4px'}} label={label} onDelete={handleOnDelete} />;
    }
  }
  if (filter.type === 'LocationFilter') {
    const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
    const { getLocationName } = useLocations(dmsDealerIdCrm);
    const { locations } = useLocations(dmsDealerIdCrm)

        if (data.dealerIds.length === 0 || (locations && data.dealerIds.length >= locations.length)) {
          let label = `Location: All Locations`;
          content = <ClickAbleChip label={label} />;
        } 
        else if (isNotNullOrUndefined(data.dealerIds)) {
          let locationNames: any[] = [];
          data.dealerIds.forEach((dealerId: any) => {
            const locationName = getLocationName(parseInt(dealerId,10))
            if (isNotNullOrUndefined(locationName)) {
            locationNames.push(locationName)
            }})
          const label = <NestedChip label="Location" subLabels={locationNames}/>;
          content = <ClickAbleChip style={{height:'auto', paddingTop:'4px',paddingBottom:'4px'}} label={label}/>;
        }
  }
  if (filter.type === 'NoSaleOptionFilter') {
    if (isNotNullOrUndefined(data.noSaleOption) && isNotNullOrUndefined(data.noSaleOptionValue)) {
      const option = NoSaleOptions.find((x) => x.value === data.noSaleOption);
      const formatLabel = option?.formatLabel.replace('{Month}', data.noSaleOptionValue);
      content = <ClickAbleChip label={formatLabel} onDelete={handleOnDelete} />;
    }
  }
  if (filter.type == 'NoSaleCalendarOption') {
    console.log("")
    if (data.noSaleFrom && data.noSaleTo) {
      let dateFrom = new Date(data.noSaleFrom).toLocaleDateString('en-GB');;
      let dateTo = new Date(data.noSaleTo).toLocaleDateString('en-GB');
      let label = `${data.noSaleOption} in ${dateFrom} - ${dateTo}`;
      content = <ClickAbleChip label={label} />;

    }
  }

  if (filter.type === 'InvoiceCalendarOption') {
    if (data.invoiceFrom && data.invoiceTo) {
      let dateFrom = new Date(data.invoiceFrom).toLocaleDateString('en-GB');;
      let dateTo = new Date(data.invoiceTo).toLocaleDateString('en-GB');

      let label = `Invoices in ${dateFrom} - ${dateTo}`;
      content = <ClickAbleChip label={label} />;
    }
  }
  if (filter.type === 'ZipCodeFilter') {
    if (data.zipCodes) {
      let label = `Zip Code: ${data.zipCodes}`;
      if (data.zipCodeInRadius) {
        label += ` for ${data.zipCodeInRadius} miles`;
      }
      content = <ClickAbleWrapChip label={label} onDelete={handleOnDelete} />;
    }
  }
  if (filter.type === 'ManufacturerFilter') {
    if (isNotNullOrUndefined(data.manufacturerCodes) && data.manufacturerCodes.length) {
      const label = <NestedChip label="Product Line" subLabels={data.manufacturerCodes.map(getManufacturerCode)} />;
      content = <ClickAbleChip style={{height:'auto', paddingTop:'4px',paddingBottom:'4px'}} label={label} onDelete={handleOnDelete} />;
    }
  }
  if (filter.type === 'CustomerCategoryFilter') {
    if (isNotNullOrUndefined(data.customerCategoryIds) && data.customerCategoryIds.length) {
      const label = (
        <NestedChip label="Customer Category" subLabels={data.customerCategoryIds.map(getCustomerCategoryName)} />
      );
      content = <ClickAbleChip style={{height:'auto', paddingTop:'4px',paddingBottom:'4px'}} label={label} onDelete={handleOnDelete} />;
    }
  }

  if (filter.type === 'PartFilter') {
    if (isNotNullOrUndefined(data.parts) && data.parts.length) {
      const label = <NestedChip label="Parts" subLabels={data.parts} />;
      content = <ClickAbleChip style={{height:'auto', paddingTop:'4px',paddingBottom:'4px'}} label={label} onDelete={handleOnDelete} />;
    }
  }

  if (filter.type === 'ZipCodeInRadius') {
    if (data.zipCodeInRadius) {
      let label = `Within ${data.zipCodeInRadius} Mile(s) `;
      content = <ClickAbleChip label={label} onDelete={handleOnDelete} />;
    }    
  }
  if (filter.type === 'CustomerTag') {
    if (data.customerTag) {
      let label = `Tag: ${data.customerTag}`;
      content = <ClickAbleChip label={label} onDelete={handleOnDelete} />;
    }    
  }


  if (filter.type === 'AmountSpent') {
    if (data.amountSpent) {
      // let label = `Minimum Amount: $${data.amountSpent.toFixed(2).toLocaleString('en-US')}`;
      let label = `Minimum Amount: $${data.amountSpent.toLocaleString('en-US',2)}`;
      let conten = CustomerCategoryReadOnly?<ClickAbleChip label={label}/>:<ClickAbleChip  label={label} onDelete={handleOnDelete}/>
      content = conten
    }    
  }

  if (filter.type === 'SalesStageId') {
    if (data.salesStageId || data.salesStageId == 0) {
      let label = `Sales Stage: ${salesStages && salesStages[data.salesStageId]}`;
      content = <ClickAbleChip label={label} onDelete={handleOnDelete} />;
    }    
  }

  if (content) {
    return (
      <Box mr={1} mb={1} onClick={onClick}>
        {content}
      </Box>
    );
  }
  return null;
}

function NestedChip({ label, subLabels, IsPhone }: { label: string; subLabels: string[]; IsPhone?:boolean }): React.ReactElement {
  return (
    <Box display="inline-flex">
      <Box mr={1}>
        <Typography variant="body1">{label}: </Typography>
      </Box>
      <Box display="inline-flex" style={{flexWrap:'wrap'}}>
        {subLabels.map(
          (x: any): React.ReactElement => (
            <ClickAbleChip
              key={x}
              label={IsPhone ? formatPhoneNumber(x):x }
              variant="outlined"
              size="small"
              style={{ backgroundColor: 'white', marginRight: '2px' }}
            />
          ),
        )}
      </Box>
    </Box>
  );
}

function LocationChip({
  dealerId,
  forAllLocation,
  onDelete,
}: {
  dealerId: number;
  forAllLocation: number;
  onDelete(event: any): void;
}): React.ReactElement {
  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  const { getLocationName } = useLocations(dmsDealerIdCrm);
  const locationName = forAllLocation === 1 ? 'All Locations' : getLocationName(dealerId);
  return <ClickAbleChip label={`Location: ${locationName}`}  />;
}
