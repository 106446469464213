import { isNotNullOrUndefined } from 'common/utils';
import CustomerGroup from './customer-group';

export interface LocationFilter {
  dealerIds?: string[];
}
export interface PeriodFilter {
  period: number;
  periodValue: number;
  fromMonth?: number;
}
export interface InvoiceTypeFilter {
  invoiceTypes?: string[];
}
export interface noSaleCalendarOption {
  noSaleFrom?: string | null | undefined;
  noSaleTo?: string | null | undefined;
  noSaleOption?: string | null | undefined;
}
export interface invoiceCalendarOption {
  invoiceFrom?: string | null | undefined;
  invoiceTo?: string | null | undefined;
}
export interface StateFilter {
  states: string[];
}
export interface PhoneNumberFilter {
  phoneNumber: string[];
}
export interface ManufacturerFilter {
  manufacturerCodes: string[];
}
export interface CustomerCategoryFilter {
  customerCategoryIds: string[];
}
export interface ZipCodeFilter {
  zipCodes: string;
  zipCodeInRadius?: number;
}

export interface ZipCodeInRadius {
  zipCodeInRadius?: number;
}


export interface NoSaleOptionFilter {
  noSaleOption: number;
  noSaleOptionValue: number;
}

export interface PartFilter {
  parts: string[];
}
export interface AmountSpent {
  amountSpent?: number;
}

export interface CustomerTag {
  customerTag?: string;
}
export interface SalesStageId {
  salesStageId?: number
}
export interface NoInvoice {
  noInvoice?: boolean
}


export type FilterName =
  | 'LocationFilter'
  | 'PeriodFilter'
  | 'InvoiceTypeFilter'
  | 'ManufacturerFilter'
  | 'CustomerCategoryFilter'
  | 'StateFilter'
  | 'ZipCodeFilter'
  | 'PhoneNumberFilter'
  | 'NoSaleOptionFilter'
  | 'PartFilter'
  | 'ZipCodeInRadius'
  | 'AmountSpent'
  | 'CustomerTag'
  | 'SalesStageId'
  | 'NoInvoice'
  | 'NoSaleCalendarOption'
  | 'InvoiceCalendarOption'

export type Filters =
  | LocationFilter
  | PeriodFilter
  | InvoiceTypeFilter
  | ManufacturerFilter
  | CustomerCategoryFilter
  | StateFilter
  | ZipCodeFilter
  | PhoneNumberFilter
  | NoSaleOptionFilter
  | PartFilter
  | ZipCodeInRadius
  | AmountSpent
  | CustomerTag
  | SalesStageId
  | NoInvoice
  | noSaleCalendarOption
  | invoiceCalendarOption
  ;

export interface FilterItem {
  type: FilterName;
  data: Filters;
}

function isDefined<T>(value: T | undefined | null): value is T {
  if (typeof value === 'string' || Array.isArray(value)) {
    return (value as T) !== undefined && (value as T) !== null && value.length > 0;
  }
  return (value as T) !== undefined && (value as T) !== null;
}

export function parseSegment(customerGroup: CustomerGroup): FilterItem[] {
  const filters: FilterItem[] = [];
  const onlyNoInvoiceSelected: boolean = customerGroup.invoiceTypes?.length == 1 && customerGroup.invoiceTypes[0].value == 5
  if (isDefined(customerGroup.locations)) {
    filters.push({
      type: 'LocationFilter',
      data: { dealerIds: customerGroup.locations },
    });
  }
  if (!isNotNullOrUndefined(customerGroup.dealerId) || (customerGroup.dealerId && customerGroup.dealerId < 0)) {
    filters.push({
      type: 'LocationFilter',
      data: { dealerIds: [] },
    });
  }
  if (isDefined(customerGroup.phoneNumber)) {
    filters.push({ type: 'PhoneNumberFilter', data: { phoneNumber: customerGroup.phoneNumber } });
  }
  if (!(customerGroup.invoiceCalendarOption) && isDefined(customerGroup.period) && isDefined(customerGroup.periodValue) && !onlyNoInvoiceSelected) {
    const data: PeriodFilter = { period: customerGroup.period, periodValue: customerGroup.periodValue, fromMonth: customerGroup.fromMonth };
    filters.push({ type: 'PeriodFilter', data });
  }
  if (!onlyNoInvoiceSelected && isDefined(customerGroup.manufacturerCodes)) {
    const data: ManufacturerFilter = { manufacturerCodes: customerGroup.manufacturerCodes };
    filters.push({ type: 'ManufacturerFilter', data });
  }
  if (isDefined(customerGroup.customerCategoryIds)) {
    const data: CustomerCategoryFilter = { customerCategoryIds: customerGroup.customerCategoryIds };
    filters.push({ type: 'CustomerCategoryFilter', data });
  }
  if (isDefined(customerGroup.invoiceType)) {
    const data: InvoiceTypeFilter = { invoiceTypes: customerGroup.invoiceTypes };
    filters.push({ type: 'InvoiceTypeFilter', data });
  }
  if (!(customerGroup?.noSaleCalendarOption) && isDefined(customerGroup.noSaleOption) && isDefined(customerGroup.noSaleOptionValue) && !onlyNoInvoiceSelected) {
    const data: NoSaleOptionFilter = {
      noSaleOption: customerGroup.noSaleOption,
      noSaleOptionValue: customerGroup.noSaleOptionValue,
    };
    filters.push({ type: 'NoSaleOptionFilter', data });
  }
  if (!onlyNoInvoiceSelected && isDefined(customerGroup?.noSaleCalendarOption) && customerGroup.noSaleCalendarOption) {
    const data: noSaleCalendarOption = {
      noSaleFrom: customerGroup.noSaleFrom,
      noSaleTo: customerGroup.noSaleTo,
      noSaleOption: customerGroup.noSaleOption == 10? 'No part sale' : (customerGroup.noSaleOption == 20? 'No WG sale' : (customerGroup.noSaleOption == 30? 'No service': null))
    }
    filters.push({ type: 'NoSaleCalendarOption', data });
  }

  if (!onlyNoInvoiceSelected && isDefined(customerGroup?.invoiceCalendarOption) && customerGroup.invoiceCalendarOption) {
    const data: invoiceCalendarOption = {
      invoiceFrom: customerGroup.invoiceFrom,
      invoiceTo: customerGroup.invoiceTo
    }
    filters.push({ type: 'InvoiceCalendarOption', data });
  }
  if (isDefined(customerGroup.zipCodes)) {
    const data: ZipCodeFilter = { zipCodes: customerGroup.zipCodes };
    if (isDefined(customerGroup.zipCodesInRadius)) {
      data.zipCodeInRadius = customerGroup.zipCodesInRadius;
    }
    filters.push({ type: 'ZipCodeFilter', data });
  }
  if (isDefined(customerGroup.zipCodesInRadius)) {
    const data: ZipCodeInRadius = { zipCodeInRadius: customerGroup.zipCodesInRadius };
    filters.push({ type: 'ZipCodeInRadius', data });
  }


  if (isDefined(customerGroup.states)) {
    const data: StateFilter = { states: customerGroup.states };
    filters.push({ type: 'StateFilter', data });
  }
  if (isDefined(customerGroup.parts) && !onlyNoInvoiceSelected) {
    const data: PartFilter = { parts: customerGroup.parts };
    filters.push({ type: 'PartFilter', data });
  }
  if (!onlyNoInvoiceSelected && isDefined(customerGroup.amountSpent)) {
    const data: AmountSpent = { amountSpent: customerGroup?.amountSpent };
    filters.push({ type: 'AmountSpent', data });
  }

  if (isDefined(customerGroup.customerTag)) {
    const data:  CustomerTag = { customerTag: customerGroup?.customerTag };
    filters.push({ type: 'CustomerTag', data });
  }
  if (isDefined(customerGroup.salesStageId)) {
    const data:  SalesStageId = { salesStageId: customerGroup?.salesStageId };
    filters.push({ type: 'SalesStageId', data });
  }
  if (isDefined(customerGroup.invoiceTypes)) {
    const invoiceType = customerGroup.invoiceTypes?.find((invoiceType: any) => invoiceType.value === 5);
    if (invoiceType) {
      filters.push({ type: 'NoInvoice', data: { noInvoice: customerGroup.noInvoice } });
    }
  }


  return filters;
}

export function deleteFilter(customerGroup: CustomerGroup, type: FilterName): CustomerGroup {
  const segmentTemp = { ...customerGroup };
  if (type === 'LocationFilter') {
    delete segmentTemp.dealerId;
  } else if (type === 'PhoneNumberFilter') {
    delete segmentTemp.phoneNumber;
  } else if (type === 'StateFilter') {
    delete segmentTemp.states;
  } else if (type === 'ZipCodeFilter') {
    delete segmentTemp.zipCodes;
    delete segmentTemp.zipCodesInRadius;
  } else if (type === 'PeriodFilter') {
    delete segmentTemp.period;
    delete segmentTemp.periodValue;
  } else if (type === 'NoSaleOptionFilter') {
    delete segmentTemp.noSaleOption;
    delete segmentTemp.noSaleOptionValue;
  } else if (type === 'ManufacturerFilter') {
    delete segmentTemp.manufacturerCodes;
  } else if (type === 'InvoiceTypeFilter') {
    delete segmentTemp.invoiceType;
  } else if (type === 'CustomerCategoryFilter') {
    delete segmentTemp.customerCategoryIds;
  } else if (type === 'PartFilter') {
    delete segmentTemp.parts;
  } else if (type === 'CustomerTag') {
    delete segmentTemp.customerTag;
  } else if (type === 'AmountSpent') {
    delete segmentTemp.amountSpent;
  } else if (type === 'SalesStageId') {
    delete segmentTemp.salesStageId;
  } else if (type == 'ZipCodeInRadius') {
    delete segmentTemp.zipCodesInRadius;
  }

  return segmentTemp;
}
